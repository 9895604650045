export default {
  getUrlQueryParameter(url, name) {
    const queryParameterString = url.split('?').length === 2 ? url.split('?')[1].split('#')[0] : ''
    const queryParameterArray = queryParameterString.split('&')
    for (const queryParameter of queryParameterArray) {
      const queryParameterSplit = queryParameter.split('=')
      if (queryParameterSplit.length === 2 && queryParameterSplit[0] === name) {
        return queryParameterSplit[1]
      }
    }
    return undefined
  },

  /**
   * @param response.data.data.userId 用户ID
   * @param response.data.data.nickname 昵称
   * @param response.data.data.loginDays 累计登录天数
   * @param response.data.data.straightLoginDays 连续登录天数
   */
  setUserInformationToSessionStorage(response) {
    window.sessionStorage.setItem('userId', response.data.data.userId)
    window.sessionStorage.setItem('nickname', response.data.data.nickname)
    window.sessionStorage.setItem('loginDays', response.data.data.loginDays)
    window.sessionStorage.setItem('straightLoginDays', response.data.data.straightLoginDays)
  },

  concatUrl(baseUrl, paramObject) {
    let url = baseUrl
    if (paramObject !== undefined && paramObject !== null) {
      url += '?'
      for (const key in paramObject) {
        const value = paramObject[key]
        url += key + '=' + value + '&'
      }
      url = url.substring(0, url.length - 1)
    }
    return url
  },

  getIndexOfFirstDifferentCharacter(string, refString) {
    if (string === refString) {
      return -1
    }
    const stringLength = string.length
    const refStringLength = refString.length
    for(let i = 0; i < stringLength && i < refStringLength; i++) {
      if (string[i] !== refString[i]) {
        return i
      }
    }
    return stringLength < refStringLength ? stringLength : refStringLength
  }
}