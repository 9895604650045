import { render, staticRenderFns } from "./ResultComponent.vue?vue&type=template&id=b160188e&scoped=true&"
import script from "./ResultComponent.vue?vue&type=script&lang=js&"
export * from "./ResultComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b160188e",
  null
  
)

export default component.exports