<template>
  <div id="container">
    <div>{{corpusName}}</div>
    <div>{{modeText}}</div>
    <div>{{currentSentenceIndex + 1}}/{{sentenceArrayLength}}</div>
    <div>{{currentSentenceHint}}</div>
    <div>
      <el-input v-model="sentenceInput" ref="sentenceInput" id="sentenceInput"
                @keyup.up.native="handleSentenceInputUp"
                @keyup.enter.native="handleSentenceInputEnter"
                @keyup.ctrl.left.native="handleSentenceInputCtrlLeftOrRight"
                @keyup.ctrl.right.native="handleSentenceInputCtrlLeftOrRight"
                @input="handleSentenceInputChange"
                :readonly="mode === 'test' && afterEnterWrong">
      </el-input>
    </div>
    <div id="hintArea" :class="hintClassObject">{{currentSentenceText}}</div>
    <div id="lastWrongArea">
      <span v-for="(item, index) in lastWrongSentenceInputArray" :key="index" :class="{wrongInput: item.different}">{{item.character}}</span>
    </div>
  </div>
</template>

<script>
import sentenceApi from '@/api/sentence'
import modeText from '@/mixin/modeText'
import utils from '@/utils/language-trainer'
import userApi from '@/api/user'

export default {
  name: 'ExerciseComponent',
  mixins: [modeText],
  props: {
    corpusName: String,
    corpusId: Number
  },
  data() {
    return {
      sentenceInput: '',
      lastWrongSentenceInput: '',
      currentSentenceIndex: 0,
      sentenceArray: [],
      sentenceInputStatus: 'white',
      mode: 'exercise',
      afterEnterWrong: false
    }
  },
  computed: {
    sentenceArrayLength() {
      if (Array.isArray(this.sentenceArray)) {
        return this.sentenceArray.length
      } else {
        return 0
      }
    },
    currentSentenceHint() {
      if (this.currentSentenceIndexInBoundsFlag) {
        return this.sentenceArray[this.currentSentenceIndex].hint
      } else {
        return ''
      }
    },
    currentSentenceText() {
      if (this.currentSentenceIndexInBoundsFlag) {
        return this.sentenceArray[this.currentSentenceIndex].text
      } else {
        return ''
      }
    },
    currentSentenceIndexInBoundsFlag() {
      return this.sentenceArrayLength > this.currentSentenceIndex
    },
    hintClassObject() {
      const hintClassObject = {}
      if (this.mode === 'learning') {
        hintClassObject.invisible = false
      } else if (this.mode === 'test') {
        hintClassObject.invisible = this.sentenceInputStatus !== 'red'
      } else if (this.mode === 'exercise') {
        hintClassObject.invisible = !this.afterEnterWrong
      } else {
        hintClassObject.invisible = true
      }
      return hintClassObject
    },
    lastWrongSentenceInputArray() {
      if (this.lastWrongSentenceInput === '') {
        return null
      }
      const differentIndex = utils.getIndexOfFirstDifferentCharacter(this.lastWrongSentenceInput, this.currentSentenceText)
      const arrayLength = differentIndex + 1 > this.lastWrongSentenceInput.length ? differentIndex + 1 : this.lastWrongSentenceInput.length
      const array = new Array(arrayLength)
      for (let i = 0; i < arrayLength; i++) {
        array[i] = {
          character: i < this.lastWrongSentenceInput.length ? this.lastWrongSentenceInput[i] : ' ',
          different: i === differentIndex
        }
      }
      return array
    }
  },
  methods: {
    handleSentenceInputEnter() {
      if (this.mode === 'test' && this.afterEnterWrong) {
        this.showResult('fail')
      } else if (this.sentenceInputStatus === 'green') {
        if (this.currentSentenceIndex + 1 < this.sentenceArrayLength) {
          this.resetSentenceStatus()
          this.currentSentenceIndex++
        } else {
          this.showResult('success')
          this.completeCorpus()
        }
      } else if (this.sentenceInput === this.currentSentenceText) {
        this.sentenceInputStatus = 'green'
      } else {
        this.sentenceInputStatus = 'red'
        this.lastWrongSentenceInput = this.sentenceInput
        this.sentenceInput = ''
        this.afterEnterWrong = true
      }
    },
    handleSentenceInputUp() {
      if (this.lastWrongSentenceInput !== '') {
        this.sentenceInput = this.lastWrongSentenceInput
      }
    },
    handleSentenceInputCtrlLeftOrRight(event) {
      if (this.mode !== 'learning') {
        return
      }
      if (event.keyCode === 37 && this.currentSentenceIndex > 0) {
        this.currentSentenceIndex--
        this.resetSentenceStatus()
      } else if (event.keyCode === 39 && this.currentSentenceIndex < this.sentenceArrayLength - 1) {
        this.currentSentenceIndex++
        this.resetSentenceStatus()
      }
    },
    resetSentenceStatus() {
      this.sentenceInput = ''
      this.lastWrongSentenceInput = ''
      this.sentenceInputStatus = 'white'
      this.afterEnterWrong = false
    },

    handleStartButtonClick(mode) {
      this.mode = mode
      sentenceApi.getSentenceByCorpusId(this.corpusId, response => {
        this.sentenceArray = response.data.data
      })
      this.resetSentenceStatus()
      this.currentSentenceIndex = 0
      this.$nextTick(() => {
        this.$refs.sentenceInput.focus()
      })
    },

    handleSentenceInputChange() {
      if (this.mode === 'test') {
        return
      }
      if (this.currentSentenceText === this.sentenceInput) {
        this.sentenceInputStatus = 'green'
      } else {
        this.sentenceInputStatus = 'white'
        if(this.mode === 'learning' || (this.mode === 'exercise' && this.sentenceInput.endsWith(' '))) {
          if (!this.currentSentenceText.startsWith(this.sentenceInput)) {
            this.sentenceInputStatus = 'red'
          }
        }
      }
    },

    showResult(result) {
      this.$emit('showResult')
      this.$bus.$emit('showResult', this.mode, result)
    },

    completeCorpus() {
      userApi.completeCorpus(this.corpusId, this.mode, () => {
        this.$message({
          message: 'Update successfully.',
          type: 'success'
        })
      }, () => {
        this.$message({
          message: 'Update failed.',
          type: 'error'
        })
      })
    }
  },

  watch: {
    sentenceInputStatus: {
      handler(newValue) {
        if (newValue === 'green') {
          this.$refs.sentenceInput.$refs.input.classList.remove('wrongInput')
          this.$refs.sentenceInput.$refs.input.classList.add('rightInput')
        } else if (newValue === 'red') {
          this.$refs.sentenceInput.$refs.input.classList.remove('rightInput')
          this.$refs.sentenceInput.$refs.input.classList.add('wrongInput')
        } else if (newValue === 'white') {
          this.$refs.sentenceInput.$refs.input.classList.remove('wrongInput')
          this.$refs.sentenceInput.$refs.input.classList.remove('rightInput')
        }
      }
    }
  },

  created() {
    this.$bus.$on('startButtonClick', this.handleStartButtonClick)
  },

  beforeDestroy() {
    this.$bus.$off('startButtonClick', this.handleStartButtonClick)
  }

}
</script>

<style scoped>
  /*noinspection CssUnusedSymbol*/
  >>> #sentenceInput.rightInput {
    color: #67C23A;
  }

  /*noinspection CssUnusedSymbol*/
  >>> #sentenceInput.wrongInput {
    color: #F56C6C;
  }

  /*noinspection CssUnusedSymbol*/
  >>> #sentenceInput.rightInput:focus {
    border-color: #67C23A;
  }

  /*noinspection CssUnusedSymbol*/
  >>> #sentenceInput.wrongInput:focus {
    border-color: #F56C6C;
  }

  /*noinspection CssUnusedSymbol*/
  .invisible {
    visibility: hidden;
  }

  /*noinspection CssUnusedSymbol*/
  .el-input {
    width: 40%;
    font-size: 20px;
  }

  #lastWrongArea {
    height: 1em;
    white-space: pre;
  }
</style>