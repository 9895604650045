import axios from "@/utils/axios"

export default {
  getCorpusListByParentId(parentId, successHandler, failHandler) {
    axios.get('api/corpus', {
      params: {
        parentId: parentId === undefined ? 0 : parentId
      }
    }).then(successHandler).catch(failHandler)
  }
}