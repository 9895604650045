import axios from "@/utils/axios"

export default {
  getSentenceByCorpusId(corpusId, successHandler, failHandler) {
    axios.get('api/sentence', {
      params: {
        corpusId: corpusId === undefined ? 0 : corpusId
      }
    }).then(successHandler).catch(failHandler)
  }
}