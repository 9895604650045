<template>
  <h3>Wait a second...</h3>
</template>

<script>
import loginApi from '@/api/login'
import utils from "@/utils/language-trainer"
export default {
  name: "LoginProcessPage",
  methods: {
    handleLogin() {
      const successHandler = response => {
        utils.setUserInformationToSessionStorage(response)
        this.$router.push('/')
        this.$message({
          message: 'Login with GitHub successfully.',
          type: 'success'
        })
      }

      const failHandler = () => {
        this.$router.push('/login')
        this.$message({
          message: 'Fail to login with GitHub.',
          type: 'error'
        })
      }

      loginApi.loginWithOauth('GitHub', successHandler, failHandler)
    }
  },
  created() {
    this.handleLogin()
  }
}
</script>

<style scoped>
  h3 {
    text-align: center;
    margin-top: 60px;
  }
</style>