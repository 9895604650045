export default {
  computed: {
    modeText() {
      if (this.mode === 'learning') {
        return '学习模式'
      } else if (this.mode === 'exercise') {
        return '练习模式'
      } else if (this.mode === 'test') {
        return '测试模式'
      } else {
        return ''
      }
    }
  }
}