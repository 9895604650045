<template>
  <el-container>
    <el-dialog title="Login Statistics" :visible.sync="showUserStatDialog" width="20%" ref="userLoginStatDialog">
      <p>Total login days: {{loginDays}}</p>
      <p>Consecutive login days: {{straightLoginDays}}</p>
      <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="showUserStatDialog = false">OK</el-button>
      </span>
    </el-dialog>
    <el-header>
      <div class="inner-header">
        <span class="logo">Language Trainer</span>
        <span>
          <font-awesome-icon icon="fa-solid fa-user" />
          {{nickname}}
        </span>
      </div>
    </el-header>
    <el-main>
      <el-container>
        <el-aside width="15%">
          <el-tree :props="treeProps" :load="loadNode" lazy @node-click="handleNodeClick"></el-tree>
        </el-aside>
        <el-main id="learningArea">
          <start-component :corpus-name="currentCorpusName" @startButtonClick="handleStartButtonClick" v-show="showStartComponent" :user-corpus-stat="currentUserCorpusStat"></start-component>
          <exercise-component :corpus-name="currentCorpusName" @showResult="handleShowResult" :corpus-id="currentCorpusId" v-show="showExerciseComponent"></exercise-component>
          <result-component :corpus-name="currentCorpusName" v-show="showResultComponent"></result-component>
        </el-main>
      </el-container>
    </el-main>
  </el-container>
</template>

<script>
import corpusApi from '@/api/corpus'
import userApi from '@/api/user'
import ExerciseComponent from '@/components/ExerciseComponent'
import StartComponent from '@/components/StartComponent'
import ResultComponent from '@/components/ResultComponent'
export default {
  name: "MainPage",
  components: {
    ResultComponent,
    StartComponent,
    ExerciseComponent,
  },
  data() {
    return {
      treeProps: {
        label: 'name'
      },
      showUserStatDialog: true,
      showComponent: '',
      currentCorpusId: undefined,
      currentCorpusName: undefined,
      currentUserCorpusStat: {
        learningCount: 0,
        exerciseCount: 0,
        testCount: 0,
        lastCompleteTime: ''
      }
    }
  },
  methods: {
    loadNode(node, resolve) {
      const parentId = node.data === undefined ? 0 : node.data.id
      corpusApi.getCorpusListByParentId(parentId, response => {
        const data = response.data.data
        if (Array.isArray(data) && data.length === 0 && this.currentCorpusId === node.data.id) {
          this.getUserCorpusStatAndShowStartComponent()
        }
        return resolve(data)
      })
    },
    handleNodeClick(data, node) {
      this.currentCorpusId = data.id
      this.currentCorpusName = data.name
      if (node.isLeaf) {
        this.getUserCorpusStatAndShowStartComponent()
      } else {
        this.showComponent = ''
      }
    },
    handleStartButtonClick() {
      this.showComponent = 'exerciseComponent'
    },
    handleShowResult() {
      this.showComponent = 'resultComponent'
    },
    getUserCorpusStatAndShowStartComponent() {
      const corpusId = this.currentCorpusId
      userApi.getUserCorpusStat(corpusId, response => {
        if (corpusId === this.currentCorpusId) {
          const data = response.data.data
          this.currentUserCorpusStat = {
            ...data
          }
        }
      })
      this.showComponent = 'startComponent'
    }
  },
  computed: {
    nickname() {
      const nickname = window.sessionStorage.getItem('nickname')
      return nickname === undefined ? 'guest' : nickname
    },

    loginDays() {
      const loginDays = window.sessionStorage.getItem('loginDays')
      return loginDays === undefined ? '0' : loginDays
    },

    straightLoginDays() {
      const straightLoginDays = window.sessionStorage.getItem('straightLoginDays')
      return straightLoginDays === undefined ? '0' : straightLoginDays
    },
    showStartComponent() {
      return this.showComponent === 'startComponent'
    },
    showExerciseComponent() {
      return this.showComponent === 'exerciseComponent'
    },
    showResultComponent() {
      return this.showComponent === 'resultComponent'
    }
  }
}
</script>

<style scoped>
  /*noinspection CssUnusedSymbol*/
  .el-header {
    background-color: #2d333b;
    line-height: 55px;
  }
  /*noinspection CssUnusedSymbol*/
  .el-container {
    height: 100%;
  }
  /*noinspection CssUnusedSymbol*/
  .el-tree {
    background-color: #1c2128;
    color: #adbac7;
  }
  .inner-header {
    display: flex;
    justify-content: flex-end;
  }
  span {
    margin-right: 20px;
  }
  .logo {
    flex: 1;
  }
  /*noinspection CssUnusedSymbol*/
  >>> #container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  >>> #container>div {
    margin-bottom: 30px;
    text-align: center;
  }
  /*noinspection CssUnusedSymbol*/
  >>> .el-tree-node:focus>.el-tree-node__content, >>> .el-tree-node__content:hover {
    background-color: #2d333b;
  }
  /*noinspection CssUnusedSymbol*/
  >>> .el-tree-node:focus>.el-tree-node__content {
    color: #409eff;
  }
  /*noinspection CssUnusedSymbol*/
  >>> .el-tree-node__expand-icon {
    color: #adbac7;
  }
  /*noinspection CssUnusedSymbol*/
  >>> .el-tree-node.is-expanded.is-current.is-focusable>.el-tree-node__content {
    color: #409eff;
  }

  #learningArea {
    margin-right: 15%;
  }


</style>