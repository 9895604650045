import VueRouter from 'vue-router'
import LoginPage from '@/pages/LoginPage'
import MainPage from "@/pages/MainPage"
import LoginProcessPage from "@/pages/LoginProcessPage"

const router = new VueRouter({
  routes: [
    {
      path: '/',
      component: MainPage
    },
    {
      path: '/login',
      component: LoginPage,
      meta: {
        noAuth: true
      },
    },
    {
      path: '/loginProcess',
      component: LoginProcessPage,
      meta: {
        noAuth: true
      }
    }
  ]
})


router.beforeEach((to, from, next) => {
  const {noAuth} = to.meta
  if (noAuth !== undefined && noAuth === true) {
    next()
  } else {
    const nickname = sessionStorage.getItem('nickname')
    if (nickname !== undefined && nickname !== null && nickname !== '') {
      next()
    } else {
      next('/login')
    }
  }
})

export default router