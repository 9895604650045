<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<!--suppress CssUnusedSymbol, CssUnusedSymbol -->
<style>
html {
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
}
#app {
  font-family: -apple-system, BlinkMacSystemFont, "Microsoft YaHei", "Segoe UI", "Helvetica Neue", Helvetica, Verdana, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: #1c2128;
  color: #adbac7;
}
/*noinspection CssUnusedSymbol*/
#app .el-input__inner {
  background-color: #22272e;
  border-color: #444c56;
  color: #adbac7;
  transition: color .2s cubic-bezier(.645,.045,.355,1), border-color .2s cubic-bezier(.645,.045,.355,1);
}
/*noinspection CssUnusedSymbol*/
#app .el-input__inner:focus {
  border-color: #409eff;
  border-width: 2px;
  padding: 0 14px;
}
/*noinspection CssUnusedSymbol*/
#app .el-button--primary {
  background-color: #373e47;
  color: #409eff;
  border-color: #525a64;
  font-weight: bold;
}
/*noinspection CssUnusedSymbol*/
#app .el-button--primary:hover, #app .el-button--primary.is-disabled {
  background-color: #444c56;
}
/*noinspection CssUnusedSymbol*/
#app .el-button--primary.is-disabled {
  color: #a0cfff;
}
/*noinspection CssUnusedSymbol*/
#app .el-dialog {
  background-color: #22272e;
}
/*noinspection CssUnusedSymbol*/
#app .el-dialog__title, #app .el-dialog__body, #app .el-dialog {
  color: #adbac7;
}
/*noinspection CssUnusedSymbol*/
#app .el-dialog__headerbtn .el-dialog__close {
  color: #768390;
}
/*noinspection CssUnusedSymbol*/
#app .el-dialog__headerbtn .el-dialog__close:hover {
  color: #409eff;
}
input:-webkit-autofill {
  -webkit-box-shadow:0 0 0 100px #22272e inset !important;
  -webkit-text-fill-color: #adbac7;
}
input::placeholder {
  color: #636e7b !important;
}
</style>
