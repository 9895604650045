import utils from "@/utils/language-trainer"
import axios from "@/utils/axios"
import qs from 'qs'

export default {
  loginWithOauth(provider, successHandler, failHandler) {
    if (provider === 'GitHub') {
      const url = window.location.href
      const code = utils.getUrlQueryParameter(url, 'code')
      const state = utils.getUrlQueryParameter(url, 'state')
      const paramObject = {
        code,
        state
      }
      axios.get(utils.concatUrl('api/login/oauth2/code/github', paramObject), {
        timeout: 5000,
      }).then(successHandler).catch(failHandler)
    }
  },

  formLogin(user, successHandler, failHandler) {
    const data = qs.stringify(user)
    axios.post('api/formLogin', data).then(successHandler).catch(failHandler)
  }
}