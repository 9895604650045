import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueRouter from "vue-router"
import VueAxios from "vue-axios"
import axios from "./utils/axios"
import './utils/element-ui'
import './utils/font-awesome'

document.title = 'Language Trainer'

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(VueAxios, axios)

new Vue({
  render: h => h(App),
  router,
  beforeCreate() {
    Vue.prototype.$bus = this
  }
}).$mount('#app')
