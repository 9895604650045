<template>
  <div class="mainArea">
    <h1>Language Trainer</h1>
    <h5>v1.0.0</h5>
    <div id="formLoginArea">
      <div class="inputArea">
        <el-input v-model="user.username" placeholder="username" ref="username"></el-input>
      </div>
      <div class="inputArea">
        <el-input v-model="user.password" placeholder="password" ref="password" show-password></el-input>
      </div>
      <el-button type="primary" @click="loginClickHandler" ref="loginButton">Login</el-button>
    </div>

    <div id="oauthLoginArea">
      <h3>also login with GitHub</h3>
      <h5>(not available now)</h5>
      <el-button type="primary" @click="gitHubClickHandler" ref="gitHubButton" disabled>
        <font-awesome-icon icon="fa-brands fa-github" size="2x" />
      </el-button>
    </div>
  </div>
</template>

<script>
import loginApi from '@/api/login'
import utils from "@/utils/language-trainer"
export default {
  name: "LoginPage",
  data() {
    return {
      user: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    gitHubClickHandler() {
      window.location.href = "/api/oauth2/authorization/github"
    },
    loginClickHandler() {
      const successHandler = response => {
        utils.setUserInformationToSessionStorage(response)
        this.$router.push('/')
        this.$message({
          message: 'Login successfully.',
          type: 'success'
        })
      }

      const failHandler = () => {
        this.$router.push('/login')
        this.$message({
          message: 'Fail to login.',
          type: 'error'
        })
      }

      loginApi.formLogin(this.user, successHandler, failHandler)
    }
  }
}
</script>

<style scoped>
  div.mainArea {
    text-align: center;
    padding-top: 60px;
  }

  div.inputArea {
    width: 300px;
    margin: 30px auto;
  }
</style>