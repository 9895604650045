<template>
  <div id="container">
    <div>{{corpusName}}</div>
    <div>Total completions: {{totalCount}}</div>
    <div>Last time: {{lastCompleteTime}}</div>
    <div>
      <el-button type="primary" round id="startLearningButton" ref="startLearningButton" class="startButton" @click="handleStartButtonClick('learning')">Learning</el-button>
      <span class="completeCount">{{learningCount}}</span>
    </div>
    <div>
      <el-button type="primary" round id="startExerciseButton" ref="startExerciseButton" class="startButton" @click="handleStartButtonClick('exercise')">Exercise</el-button>
      <span class="completeCount">{{exerciseCount}}</span>
    </div>
    <div>
      <el-button type="primary" round id="startTestButton" ref="startTestButton" class="startButton" @click="handleStartButtonClick('test')">Test</el-button>
      <span class="completeCount">{{testCount}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StartComponent',
  props: {
    corpusName: String,
    userCorpusStat: {
      type: Object,
      default() {
        return {
          learningCount: 0,
          exerciseCount: 0,
          testCount: 0,
          lastCompleteTime: ''
        }
      }
    }
  },
  methods: {
    handleStartButtonClick(mode) {
      this.$emit('startButtonClick')
      this.$bus.$emit('startButtonClick', mode)
    }
  },
  computed: {
    totalCount() {
      return this.userCorpusStat.learningCount + this.userCorpusStat.exerciseCount + this.userCorpusStat.testCount
    },
    lastCompleteTime() {
      return this.userCorpusStat.lastCompleteTime
    },
    learningCount() {
      return this.userCorpusStat.learningCount
    },
    exerciseCount() {
      return this.userCorpusStat.exerciseCount
    },
    testCount() {
      return this.userCorpusStat.testCount
    }
  }
}
</script>

<style scoped>
  .startButton {
    width: 10%;
  }
  .completeCount {
    margin-left: 10px;
  }
</style>