import axios from 'axios'
import router from "@/router"
import {Message} from "element-ui"

axios.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response && error.response.status === 401) {
    const route = router.currentRoute
    if (route.path !== '/login') {
      router.push('/login').then(() => Message({
        message: 'Please login first.',
        type: 'error'
      }))
    }
  }
  return Promise.reject(error)
})

export default axios

