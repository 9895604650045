import axios from "@/utils/axios"

export default {
  getUserCorpusStat(corpusId, successHandler, failHandler) {
    axios.get('api/userCorpusStat', {
      params: {
        corpusId: corpusId
      }
    }).then(successHandler).catch(failHandler)
  },

  completeCorpus(corpusId, corpusModeName, successHandler, failHandler) {
    const data = {
      corpusId,
      corpusModeName
    }
    axios.post('api/completeCorpus', data).then(successHandler).catch(failHandler)
  }
}