<template>
  <div id="container">
    <div>{{corpusName}}</div>
    <div>{{modeText}}</div>
    <div>{{resultText}}</div>
  </div>
</template>

<script>
import modeText from '@/mixin/modeText'

export default {
  name: 'ResultComponent',
  mixins: [modeText],
  data() {
    return {
      mode: '',
      result: ''
    }
  },
  props: {
    corpusName: String
  },
  computed: {
    resultText() {
      if (this.result === 'success') {
        return '完成'
      } else if (this.result === 'fail') {
        return '失败'
      } else {
        return ''
      }
    }
  },
  methods: {
    handleShowResult(mode, result) {
      this.mode = mode
      this.result = result
    }
  },

  created() {
    this.$bus.$on('showResult', this.handleShowResult)
  },

  beforeDestroy() {
    this.$bus.$off('showResult', this.handleShowResult)
  }
}
</script>

<style scoped>

</style>